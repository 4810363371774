import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMasterListState } from "../masterList/masterListProvider";

// Fix default marker icon issue in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: process.env.PUBLIC_URL + '/mapImages/marker-icon-2x.png',
    iconUrl: process.env.PUBLIC_URL + '/mapImages/marker-icon.png',
    shadowUrl: process.env.PUBLIC_URL + '/mapImages/marker-shadow.png',
});

const MapComponent = () => {
    const defaultPosition = [19.796597, 72.733738];
    const { gatewayList } = useMasterListState();
    // console.log("in map component:", gatewayList);
    return (
        <MapContainer
            center={defaultPosition}
            zoom={14}
            style={{ height: "530px", width: "100%" }}
        >
            {/* Add a TileLayer for the map */}
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* Map over data to create Markers */}
            {gatewayList.map((item, index) => (
                <Marker key={index} position={[item.latitude, item.longitude]}>
                    <Popup>
                        <strong className="is-bold is-size-7">{item.name}</strong>
                        <br />
                        Lat: {item.latitude}, Lon: {item.longitude}
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default MapComponent;
