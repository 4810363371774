import { EditIcon } from "core/components/svgIcon";
import { Table } from "core/components/table";
import { useModal } from "core/hooks/modalHook";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { fetchAllCompany } from "./companyService";
import { useLoading } from "core/hooks/loadingHook";
import { useMasterListState } from "../masterList/masterListProvider";
import { fetchCompanyByUser } from "./companyService";

export const CompanyTable = ({ onEdit }) => {
  const { loaderContainer, startLoading, stopLoading } = useLoading("absolute");
  const [data, setData] = useState([]);
  const { user } = useMasterListState();
  // console.log("user in company table: ", user);


  const handleEditAction = (value) => {
    onEdit(value);
  };

  const ActionButtonRenderer = ({ value }) => {
    return (
      <React.Fragment>
        <button
          className="button is-small is-primary has-text-weight-medium my-1 mt-2"
          onClick={() => handleEditAction(value)}
        >
          {/* <EditIcon /> */}
          Details
        </button>
      </React.Fragment>
    );
  };

  const columnDefs = [

    {
      headerName: "Id",
      field: "id",
      maxWidth: 100,
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 250,
    },
    {
      headerName: "Address",
      field: "plotNo",
      minWidth: 250,
    },
    {
      headerName: "Contact Person",
      field: "contactPerson",
      minWidth: 250,
    },
    {
      headerName: "Contact No.",
      field: "contactNo",
      minWidth: 150,
    },
    // {
    //   headerName: "Status",
    //   field: "status",
    //   minWidth: 100,
    // },
    {
      headerName: "Details",
      minWidth: 150,
      floatingFilter: false,
      valueGetter: function (params) {
        return params.data;
      },
      cellRenderer: ActionButtonRenderer,
      cellRendererParams: {},
    },
  ];

  if (user.role === "ADMIN") {
    // Add additional column only for admins
    const statusColumn = { headerName: "Status", field: "status", minWidth: 100, sort: "desc" };
    columnDefs.splice(columnDefs.length - 1, 0, statusColumn);
  }
  // const columnDefs = [
  //   {
  //     // headerName: "Company",
  //     children: children,
  //   },
  // ];

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        if (user.role === "USER") {
          // console.log("in comapny user")

          const { data: companies } = await fetchCompanyByUser(user["id"]);
          const data = companies;

          // console.log("company table data :", companies);
          setData(data);
        } else if (user.role === "ADMIN") {
          const { data: companies } = await fetchAllCompany();
          const data = companies;
          setData(data);

        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
      stopLoading();
    };

    fetchData();
  }, [user]);

  return (
    <React.Fragment>
      <div className="card mx-2 my-2 ">
        <Table
          rowData={data}
          columnDefs={columnDefs}
          pagination={true}
          theme="alpine"
          defaultColDef={{
            sortable: true, // Enable sorting
            filter: true, // Enable filtering
          }}
        />
      </div>

      {loaderContainer}
    </React.Fragment>
  );
};
