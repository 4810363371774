import { ConnectionCard } from 'core/features/homeCards/connection__card'
import { OfflineCard } from 'core/features/homeCards/offline__card'
import { OnlineCard } from 'core/features/homeCards/online__card'
import { ZoneCard } from 'core/features/homeCards/zone__card'
import MapComponent from 'core/features/map/gatewayMap'
import React from 'react'

export const Home = () => {
    return (
        <div>
            <div class="columns is-gapless ">
                <div className="column is-half">
                    <div className="columns is-multiline mt-5 mx-2">
                        <div className="column is-half">
                            <ConnectionCard />
                        </div>
                        <div className="column is-half">
                            <ZoneCard />
                        </div>
                        <div className="column is-half">
                            <OnlineCard />
                        </div>
                        <div className="column is-half">
                            <OfflineCard />
                        </div>
                    </div>
                </div>
                <div className="column is-half mt-6">
                    <div className="card is-full-height mr-3">
                        <MapComponent />
                    </div>
                </div>
            </div>
        </div>

    )
}

