import _ from "lodash";
import Joi from "joi";
import { toast } from "react-toastify";
import moment from "moment";
import { generateReport } from "./reportService";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { downloadReport } from "core/utility/util";
import { FormCard } from "core/cards/formCard";
import { useMasterListState } from "../masterList/masterListProvider";
import { DateField } from "core/components/inputWithAccessControl";
import { fetchCompanyByUser } from "../companyData/companyService";

export const DailyConsumptionReportCompany = () => {
    const { loaderContainer, startLoading, stopLoading } = useLoading();

    const { user } = useMasterListState();

    const defaultInputs = {};
    const submitCallback = async () => {
        startLoading();
        const { data: companies } = await fetchCompanyByUser(user["id"]);
        // console.log("companies", companies);
        const { fromDate, toDate } = inputs;
        const fromUnix = moment(fromDate).unix();
        const toUnix = moment(toDate).add(moment.duration("23:59:59")).unix();
        if (companies) {
            // console.log(companies[0].id);
            const body = {
                templateName: "lorawan_daily_consumption_report",
                reportName: "Company_Daily_Consumption_Report",
                exportFormat: "pdf",
                input: {
                    company_id: companies[0].id,
                    fromUnix: fromUnix,
                    toUnix: toUnix,
                },
            };
            // console.log("body", body);

            try {
                const response = await generateReport(body);
                let fileName = response.headers["x-blob-file"];
                downloadReport(fileName, response);
                toast.success("Report generated successfully");
                stopLoading();
            } catch (error) {
                console.log(error);
                toast.error("Error while generating report");
                stopLoading();
            }
        }
    };
    const schema = Joi.object({
        fromDate: Joi.date().label("From Date").required(),
        toDate: Joi.date().label("To Date").required(),
    });
    const { inputs, errors, handleInputChange, handleSubmit, resetInput } =
        useSubmitForm(schema, submitCallback, defaultInputs);
    const accessToEdit = true;
    return (
        <div className="card mx-2 my-2">
            <FormCard
                formName={"Daily Consumption Data Report"}
                onSubmit={handleSubmit}
                submitAccess={true}
            >
                <div className="columns is-multiline">
                    <div className="column is-one-third">
                        <DateField
                            identifier="fromDate"
                            labelName="From Date"
                            step={5}
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                        />
                    </div>
                    {/* /.column */}

                    <div className="column is-one-third">
                        <DateField
                            identifier="toDate"
                            labelName="To Date"
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                            editAccess={true}
                        />
                    </div>
                    {/* /.column */}


                </div>
                {/* /.columns */}
            </FormCard>
            {loaderContainer}
        </div>
    );
};
