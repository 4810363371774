import moment from "moment";
import React, { Component } from "react";

export const ConsumptionCard = ({ date, consumption }) => {

  const formattedDate = moment(date).subtract(1, 'month').format("DD-MM-YYYY");
  const roundedValue = consumption.toFixed(2);

  return (
    <div className="consumption-card">
      <div className="consumption-card__name ">Consumption for : {formattedDate}</div>
      <div className="consumption-card__value">{roundedValue} m3/day</div>
      {/* <div className="consumption-card__standard">
      ""
      </div> */}
      {/* <div className="parameter-card-2__range">{`Range : ${rangeMin} - ${rangeMax} ${unit}`}</div> */}
    </div>
  );
};
