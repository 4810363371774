import useWindowDimensions from "core/hooks/windowDimensionsHook";
import { logout } from "core/features/authentication/authService";
import { FeatureProvider } from "core/features/feature/featureProvider";
import { AuthenticationValidator } from "core/features/authentication/authenticationValidator";
import { MasterlistProvider } from "core/features/masterList/masterListProvider";
import React, { useState } from "react";
import { Redirect, Route, Routes, Navigate } from "react-router-dom";
import { Dashboard } from "./dashboard";

import roles from "roles.json";
import { PermissionProvider } from "core/features/user/permissionProvider";
import { ErrorBoundary } from "core/components/errorBoundary";
import { CompanyAction } from "./companyAction";
import { UserProfile } from "./profile";
import { Home } from "./home";
import { ReportAction } from "./reportAction";
import { ZoneAction } from "./zoneAction";
import { AdminHeader } from "./adminHeader";
import { AdminNavbar } from "./adminNavbar";
import MapComponent from "core/features/map/gatewayMap";

export const Admin = (props) => {
  const handleLogout = () => {
    logout();
    props.history.push("/");
  };

  const { width, height } = useWindowDimensions();

  const options = { requiredRole: roles.ADMIN_ROLE };
  return (
    <React.Fragment>
      <ErrorBoundary>
        <MasterlistProvider>
          <AuthenticationValidator options={options}>
            <PermissionProvider>
              <div className="main">
                <header className="header">
                  <AdminHeader
                    // toggleSidebar={toggleSidebar}
                    onLogout={handleLogout}
                  />
                </header>
                <div className="content-area">
                  <div >
                    <AdminNavbar />
                  </div>
                  <div className="content-container">
                    <Routes>
                      <Route path="home" element={<Home />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="companies" element={<CompanyAction />} />
                      <Route path="profile" element={<UserProfile />} />
                      <Route path="reports" element={<ReportAction />} />

                      <Route path="zones" element={<ZoneAction />} />





                      <Route path="/" element={<Navigate to="dashboard" />} />
                    </Routes>
                  </div>
                  {/* /.content-container */}
                </div>
                {/* /.content-area */}
              </div>
              {/* /.main */}
            </PermissionProvider>
          </AuthenticationValidator>
        </MasterlistProvider>
      </ErrorBoundary>
    </React.Fragment>
  );
};
