import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export const CompanyNavbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [click, setClick] = useState(true);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleSubMenu = () => {
        if (window.innerWidth < 728) {
            setSubMenuOpen(!isSubMenuOpen);
        }
    };
    const changeClick = () => setClick(!click);

    return (
        <div className="navbar-container">
            <nav className="navbar  is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link className="navbar-item has-text-weight-semibold" to="/company/companies">
                        {/* <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28" /> */}
                        <strong>VAA-AMR</strong>
                    </Link>

                    <a role="button" onClick={() => {
                        setIsNavExpanded(!isNavExpanded);
                    }} style={{ color: "#fff" }}
                        className={
                            isNavExpanded ? "navbar-burger is-active " : "navbar-burger "
                        }
                        aria-label="menu"
                        aria-expanded="false"
                    >                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarBasicExample" className={`navbar-menu${isNavExpanded ? "navbar-menu" : ""}`} onClick={() => setNavbarOpen(false)}>
                    <div className="navbar-start">
                        {/* <Link to="/company/home" className="navbar-item ">
                            Home
                        </Link> */}

                        <Link to="/company/companies" className="navbar-item ">
                            Home
                        </Link>
                        {/* <Link to="/company/zones" className="navbar-item ">
                            Zone
                        </Link> */}
                        {/* <Link to="/company/dashboard" className="navbar-item ">
                            Analytics
                        </Link> */}
                        <Link to="/company/reports" className="navbar-item ">
                            Reports
                        </Link>



                        <div className="navbar-item has-dropdown is-hoverable" onClick={toggleSubMenu}>
                            <a className="navbar-link" >
                                About
                            </a>

                            <div className={isSubMenuOpen ? "navbar-dropdown" : "navbar-dropdown submenu_value"}>
                                <Link className="navbar-item is-size-6" to="/company/profile">
                                    Change Password
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons" >
                                <Link to="/" className="button">
                                    <strong>Logout</strong>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div >
    )
}
