import {
  CrossIcon,
  EarthIcon,
  FactoryIcon,
  TimerIcon,
} from "core/components/svgIcon";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDefaultReadings } from "./nodeService";
import ParameterCyclicChart from "./parameterCyclicChart";
import { getAssignedParametersByCompany } from "./nodeService";
import { ParametersCarouselCard } from "./cards/parametersCarouselCardV2";
import { useLiveReadingHook } from "./liveReadingHook";
import { ConsumptionCarouselCard } from "./cards/consumptionCarouselCard";
import { fetchRecentDailyConsumptionByCompany } from "./companyService";
import { searchNode } from "./nodeService";
import { SerialNumberIcon } from "core/components/svgIcon";
import { MonthlyConsumptionReport } from "../reports/monthlyConsumptionReport";
import MonthlyConsumptionChart from "./monthlyConsumptionChart";

export const NodeModal = ({ open, onClose, selectedData }) => {
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const [liveReadings] = useLiveReadingHook(selectedData["id"]);

  const interval = 15;
  const companyId = selectedData["id"];

  const [nodeInfo, setNodeInfo] = useState({});
  const [readingMap, setReadingMap] = useState({});
  const [consumptionDetails, setConsumptionDetails] = useState([]);
  const [nodes, setNodes] = useState([]);


  useEffect(() => {

    const fetchData = () => {
      try {

        const { parameterValues, epochTime } = liveReadings[0];
        // console.log("parameter values:", parameterValues);
        let copy = { ...readingMap };
        const filteredValues = parameterValues.filter(value => value.n === "tl");
        _.forEach(filteredValues, function (value) {
          copy[value["n"]] = value["pv"];
        });
        // console.log("copy :", copy);


        setReadingMap(copy);
        // console.log("reading map :", readingMap)
        nodeInfo["dataFetchedAt"] = new Date(epochTime * 1000).toLocaleString();
        setNodeInfo(nodeInfo);
      } catch (ex) {
        toast.error("Error while fetching data");
      }
    };
    if (liveReadings.length > 0) {
      fetchData();
    }
  }, [liveReadings]);

  const [rangeValue, setRangeValue] = useState(1);
  const [hideCharts, setHideCharts] = useState(true);
  const [readings, setReadings] = useState([]);
  const [parameters, setParameters] = useState([]);


  useEffect(() => {
    setHideCharts(true);
    startLoading();
    if (!_.isEmpty(selectedData)) {


      const fetchData = async () => {
        // console.log(selectedData);
        const dateTo = moment();
        const dateFrom = moment().subtract(rangeValue, "d");
        const toUnix = moment(dateTo, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();
        const fromUnix = moment(dateFrom, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();
        let values = [];

        try {
          const { data: parameters } = await getAssignedParametersByCompany(companyId);
          const filteredParameters = parameters.filter(parameter => parameter.identifier === "TL");
          setParameters(filteredParameters);
          // console.log(parameters);

          const { data: inputValues } = await getDefaultReadings(
            companyId,
            fromUnix,
            toUnix,
          );
          values = inputValues;
          // console.log("input values", inputValues);
        } catch (error) {
          console.log(error);
        }
        if (values.length > 0) {
          stopLoading();
          setReadings(values);
          setRangeValue(rangeValue);

          let paramRows = values.length;

          setHideCharts(false);
        } else {
          stopLoading();
          toast.warn("No data found");
        }
      };
      fetchData();
    }

  }, [rangeValue, selectedData]);

  useEffect(() => {
    if (!_.isEmpty(selectedData)) {
      const fetchData = async () => {
        try {
          const from = moment().subtract(3, "d").format("yyyy-MM-DD");
          const to = moment().subtract(0, "d").format("yyyy-MM-DD");
          // console.log("body :", companyId, from, to);

          const { data: consumptionData } = await fetchRecentDailyConsumptionByCompany(companyId, from, to);
          // console.log("consumption data :", consumptionData)
          setConsumptionDetails(consumptionData);
          const { data: nodes } = await searchNode(companyId);
          setNodes(nodes);
          // console.log(nodes);

        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }

  }, [selectedData]);

  const handleRangeChange = async (event) => {
    const rangeValue = event.target.value;
    if (rangeValue) {
      setRangeValue(rangeValue);
    }
  };

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div className="node-modal">
        <div className="card node-modal-card">
          <header className="card-header">
            <div className="card-header-title">
              <span className="is-size-4 has-text-centered">{selectedData.name}</span>
            </div>

            <span
              className="svgicon is-24x24 is-padding-small"
              style={{ cursor: "pointer" }}
              onClick={onClose}
            >
              <CrossIcon />
            </span>
          </header>
          <div className="card-content node-modal-card-content">
            <div className="columns is-mobile is-multiline box">
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <FactoryIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase ">{selectedData.name}</p>
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <EarthIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase">{selectedData.plotNo}</p>
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <TimerIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase">
                    Last data received at: {nodeInfo.dataFetchedAt}
                  </p>
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <SerialNumberIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase">Serial No. : {nodes.length > 0 && nodes[0].serialNumber}</p>
                </div>
              </div>

            </div>
            {/* /.header */}
            <div className="columns">
              <div className="column is-one-third">

                <div className="node-modal__parameter-carousel box">
                  <ParametersCarouselCard
                    parameters={parameters}
                    readingMap={readingMap}
                  />
                  {/* </div> */}
                </div>

              </div>
              <div className="column is-one-third">

                <div className="node-modal__parameter-carousel box">
                  <ConsumptionCarouselCard
                    consumptionDetails={consumptionDetails}
                  />
                  {/* </div> */}
                </div>

              </div>
            </div>
            {/* /.carousel-row */}
            <div className="column">
              <div className="field ">
                <p className="label">Select Range </p>
                <div className="field-body buttons" onClick={handleRangeChange}>
                  <button className="button is-primary is-right" value={1}>
                    Daily
                  </button>
                  <button className="button is-primary is-right" value={7}>
                    Weekly
                  </button>
                  <button className="button is-primary is-right" value={30}>
                    Monthly
                  </button>
                </div>
              </div>
            </div>
            <div className="column is-full">
              {!hideCharts && (
                <div className="box">
                  <div className="columns is-centered">
                    <div className="column is-11">
                      <ParameterCyclicChart
                        parameters={parameters}
                        readings={readings}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="column is-full">

              <div className="box">
                <div className="columns is-centered">
                  <div className="column is-11">
                    <MonthlyConsumptionChart
                      selectedData={selectedData}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* ./card-content */}
        </div>
        {/* /.card ./node-details-card */}
        {loaderContainer}
      </div>
    </Modal>
  );
};
