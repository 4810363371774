import React from "react";
import { CompanyConsumptionReport } from "core/features/reports/companyConsumptionReport";
import { DailyConsumptionReport } from "core/features/reports/dailyConsumptionReport";
import { MonthlyConsumptionReport } from "core/features/reports/monthlyConsumptionReport";
import { CompanyActionReport } from "core/features/reports/companyActionReport";
import { CumulativeActionReport } from "core/features/reports/cumulativeActionReport";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { addDefaultOption } from "core/utility/util";
import { formatArrayToOptions } from "core/utility/util";
import { DetailedConsumptionReport } from "core/features/reports/detailedConsumptionReport";
import { useMasterListState } from "core/features/masterList/masterListProvider";
import { DailyConsumptionReportCompany } from "core/features/reports/dailyConsumptionReportCompany";

export const ReportAction = () => {

    const { user } = useMasterListState();

    const reportOptions = [
        // "CompanyWise Data Report",
        "Daily Consumption Data Report",

    ]

    const reportNameOptions = addDefaultOption(formatArrayToOptions(reportOptions));

    const schema = Joi.object({
        reportName: Joi.string().label("Report Name").allow(""),
    });
    const { inputs, errors, handleInputChange, handleSubmit, resetInput } =
        useSubmitForm(schema);
    const accessToEdit = true;
    return (
        <React.Fragment>
            {/* <div className="columns is-multiline">
                <div className="column is-half is-offset-one-quarter pt-5 ">
                    <SelectContainer
                        identifier="reportName"
                        labelName="Select Report"
                        handleInputChange={handleInputChange}
                        inputs={inputs}
                        errors={errors}
                        editAccess={true}
                        options={reportNameOptions}
                        required={false}

                    />
                </div>
                <div >
                </div>
            </div>
            {!inputs.reportName ?
                <div className="pt-5"><MonthlyConsumptionReport /></div> : ""

            } */}


            <div className="pt-5"><DailyConsumptionReportCompany /></div>





        </React.Fragment>
    );
};
